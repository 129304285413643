
  import { mapGetters } from 'vuex'
  import NavigationButtons from '~/components/marketplace/new_check/NavigationsButtons.vue'
  import CandidateInfo from '~/components/marketplace/CandidateInfo.vue'

  export default {
    name: `StepperStepCandidate`,
    components: {
      CandidateInfo,
      NavigationButtons
    },
    props: {
      completedSteps: {
        type: [Array, Number],
        default: () => []
      }
    },
    data() {
      return {
        toggleFreeze: false,
      }
    },
    computed: {
      ...mapGetters({
        candidateForm: `marketplace/candidateForm`,
        candidateFormAnswers: `marketplace/candidateFormsAnswers`
      }),
      isComplete() {
        return this.completedSteps.includes(2)
      }
    },
    watch: {
      candidateForm() {
        this.resetObsValidation()
      }
    },
    methods: {
      clearStep() {
        this.clearComponentValues()
        this.resetObsValidation()
        this.$emit(`clear-step`, 2)
      },
      clearComponentValues() {
        this.$refs.candidateForm.clearValues()
      },
      resetObsValidation() {
        this.$refs.candidateInfoObs.reset()
      },
      async nextStep() {
        const offset = 70
        const isValid = await this.isObsValid()
        !isValid && this.$vuetify.goTo(this.$refs.candidateForm, { offset })
        isValid && this.$emit(`next-step`, 3)
      },
      async isObsValid() {
        return await this.$refs.candidateInfoObs.validate()
      },
      freeze() {
        this.toggleFreeze = true
      },
      defrost() {
        this.toggleFreeze = false
      }
    }
  }

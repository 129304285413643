
  import { mapGetters } from 'vuex'

  export default {
    name: `CandidateInfo`,
    data() {
      return {
        key: 0,
      }
    },
    props: {
      readonly: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters({
        candidateForm: `marketplace/candidateForm`,
        candidateFormAnswers: `marketplace/candidateFormsAnswers`,
        joinedDocuments: `marketplace/joined_documents`
      }),
      orderedFormElements() {
        const _candidateForm = this.$helpers.sortElements(this.candidateForm, (a, b) => a[1].order - b[1].order)
        return _candidateForm
      }
    },
    async destroyed() {
      await this.$store.commit(`marketplace/defaultCandidateForm`)
      await this.$store.commit(`marketplace/defaultCandidateFormAnswers`)
    },
    methods: {
      getInitialValue(name) {
        if (this.candidateFormAnswers[name]) {
          return this.candidateFormAnswers[name]
        } else if (this.candidateForm[name] && this.candidateForm[name].options?.initial?.value) {
          if (Object.keys(this.candidateFormAnswers).includes(name)) {
            this.saveAnswers(name, this.candidateForm[name].options.initial.value)
          }
          return this.candidateForm[name].options.initial.value
        }
        return undefined;
      },
      saveAnswers(name, value) {
        this.$store.commit(`marketplace/updateCandidateAnswer`, { name, value })
      },
      resetAnswer(name) {
        if (this.candidateForm[name] && this.candidateForm[name].options?.initial?.value) {
          this.saveAnswers(name, this.candidateForm[name].options?.initial?.value)
        } else {
          this.saveAnswers(name, undefined)
        }
      },
      refreshComponent() {
        this.key += 1
      },
      restoreDefaultAnswer() {
        Object.keys(this.candidateFormAnswers).forEach( key => this.resetAnswer(key))
      },
      clearValues() {
        this.restoreDefaultAnswer()
        this.refreshComponent()
      },
      isDisplayed(question) {
        const display = this.$shared.isDisplayed(question, this.candidateFormAnswers)
        if (!display && question && question.title && this.candidateFormAnswers[question.title] !== undefined) {
          this.resetAnswer(question.title);
        }
        return display
      },
    }
  }
